import { Button, Input } from "components";
import Accordion from "components/Accordion";
import CollapsingCard from "components/Card/CollapsingCard";
import {
  CustomSelectForm,
  InputForm,
  NumberInputForm,
  SelectCoaByLevelForm,
} from "components/HookForm";
import { useTaxesQuery } from "hooks/externalService";
import { map, sumBy } from "lodash";
import React, { useContext, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BiPlus, BiTrash } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router";

const Contractual = ({ disabled }) => {
  const location = useLocation();

  const { control, watch, resetField } = useFormContext();
  const {
    fields: accounts,
    append: addAccount,
    remove: removeAccount,
  } = useFieldArray({
    control,
    name: "accounts",
  });

  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  useEffect(() => {
    !isDetail && resetField("total_trx_value", {
      defaultValue: sumBy(watch("accounts"), (account) =>
        account.activity_details?.length > 0
          ? sumBy(
              account.activity_details,
              (activity_detail) =>
                parseFloat(
                  activity_detail?.unit_cost ? activity_detail?.unit_cost : 0
                ) *
                  (parseFloat(
                    activity_detail?.volume ? activity_detail?.volume : 0
                  ) *
                    parseFloat(
                      activity_detail?.qty ? activity_detail?.qty : 0
                    )) +
                parseFloat(
                  activity_detail.taxes?.reduce(
                    (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                    0
                  )
                )
            )
          : 0
      ),
    });
  }, [
    sumBy(watch("accounts"), (account) =>
      sumBy(
        account.activity_details,
        (activity_detail) =>
          parseFloat(activity_detail?.unit_cost) *
            (parseFloat(activity_detail?.volume) +
              parseFloat(activity_detail?.qty)) +
          parseFloat(
            activity_detail.taxes?.reduce((acc, o) => acc + o?.value, 0)
          )
      )
    ),
  ]);

  return (
    <div className="space-y-4">
      {map(accounts, (account, key) => (
        <div key={account.id} className="space-y-4">
          <Accordion
            label={`Akun Belanja ${key + 1}`}
            renderRight={
              !isDetail && key > 0
                ? () => (
                    <Button
                      iconOnly
                      startIcon={<BiTrash size={20} />}
                      className="px-2 mr-2 bg-red-600 border-red-600"
                      onClick={() => removeAccount(key)}
                    />
                  )
                : null
            }
          >
            <div className="space-y-4">
              <SelectCoaByLevelForm
                name={`accounts.${key}.shipping_account`}
                className="w-full"
                label="Akun Belanja"
                placeholder="Pilih akun belanja"
                level={9}
                parent_id={watch("component")?.id}
                menuPortalTarget={document.body}
                isDisabled={disabled}
                onSelect={() => {
                  resetField(`accounts.${key}.activity_details`, {
                    defaultValue: [
                      {
                        activity_detail: null,
                        unit_cost: "",
                        qty: "",
                        volume: "",
                        taxes: [
                          {
                            tax: null,
                            billing_code: "",
                            value: "",
                          },
                        ],
                      },
                    ],
                  });
                }}
              />
              <AccountDetail name={`accounts.${key}`} disabled={disabled} />
            </div>
          </Accordion>
        </div>
      ))}
      <div className="flex items-center justify-end">
        {!disabled && (
          <Button
            startIcon={<FiPlus />}
            className="p-4 border rounded-lg bg-primary-600 border-primary-600 w-40"
            onClick={() =>
              addAccount({
                shipping_account: null,
                activity_details: [
                  {
                    activity_detail: null,
                    unit_cost: "",
                    qty: "",
                    volume: "",
                    taxes: [
                      {
                        tax: null,
                        billing_code: "",
                        value: "",
                      },
                    ],
                  },
                ],
              })
            }
          >
            Tambah Akun
          </Button>
        )}
      </div>
    </div>
  );
};

export default Contractual;

export const TaxForm = ({ name, disabled }) => {
  const methods = useFormContext();
  const {
    fields: taxesField,
    remove: removeTax,
    append: appendTax,
    replace: replaceTax,
  } = useFieldArray({
    control: methods.control,
    name: `${name}.taxes`,
  });

  useEffect(() => {
    if (taxesField?.length === 0) {
      replaceTax([
        {
          tax: null,
          billing_code: "",
          value: 0,
        },
      ]);
    }
  }, []);

  const { data: taxes } = useTaxesQuery(["dt-realization-taxes"]);

  const getTaxAmount = (name) =>
    taxes?.find((tax) => tax?.nama === name)?.value / 100 || 0;

  const calculateTaxValue = (name, index) => {
    const unitCost = parseFloat(methods.watch(`${name}.unit_cost`)) || 0;
    const volume = parseFloat(methods.watch(`${name}.volume`)) || 0;
    const qty = parseFloat(methods.watch(`${name}.qty`)) || 0;
    const taxLabel = methods.watch(`${name}.taxes.${index}.tax`)?.label || '';
    return unitCost * (volume + qty) * getTaxAmount(taxLabel);
  };

  return (
    <>
      <span className="span text-primary-600 font-bold text-lg">Pajak</span>
      {taxesField?.map((field, index) => (
        <div className="flex flex-row items-end gap-4" key={field.id}>
          <CustomSelectForm
            name={`${name}.taxes.${index}.tax`}
            options={taxes?.map((tax) => ({
              label: tax?.nama,
              value: tax?.id,
            }))}
            label="Pajak"
            placeholder="Pajak"
            isDisabled={disabled}
          />
          <InputForm
            name={`${name}.taxes.${index}.billing_code`}
            label="Kode Billing"
            placeholder="Masukkan kode billing"
            className="w-full"
            disabled={disabled}
          />
          <NumberInputForm
            name={`${name}.taxes.${index}.value`}
            label="Nilai Pajak (Rp)"
            className="w-full"
            placeholder="0"
            value={calculateTaxValue(name, index) || 0}
            disabled
          />
          {index > 0 && !disabled && (
            <Button
              className="bg-red-600 border-red-600"
              startIcon={<BiTrash size={18} />}
              iconOnly
              onClick={() => removeTax(index)}
            />
          )}
        </div>
      ))}

      <div className="flex flex-1 flex-col gap-4 items-end border-y-[1px] border-gray-100 mt-4 py-4">
        {!disabled && (
          <Button
            onClick={() => {
              appendTax({
                tax_id: "",
                tax: null,
                billing_code: "",
                value: 0,
              });
            }}
            className="bg-white border-gray-600 text-gray-600 hover:text-white"
            startIcon={<BiPlus />}
          >
            Tambah Pajak
          </Button>
        )}
        <div className="space-y-2">
          <label className="flex gap-1 font-semibold text-[14px]">
            <span className={`label-text`}>Total Pajak (Rp)</span>
          </label>
          {console.log(methods.watch(`${name}.taxes`))}
          <NumericFormat
            value={methods
              .watch(`${name}.taxes`)
              ?.reduce(
                (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                0
              )}
            placeholder="0"
            className="w-full"
            customInput={Input}
            allowLeadingZeros
            thousandSeparator="."
            decimalSeparator=","
            disabled
          />
        </div>
        <span className="text-sm inline-block font-semibold bg-primary-50 text-primary-600 rounded-xl px-3 py-1">
          Total Realisasi (Rp):{" "}
          <span className="font-bold">
            {new Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(
              // methods
              //   .watch(`${name}.taxes`)
              //   ?.reduce((acc, o) => acc + o?.value, 0) +
              //   methods.watch(`${name}.realization_volume`) *
              //     methods.watch(`${name}.realization_value`) || 0
              parseFloat(
                methods.watch(`${name}.unit_cost`)
                  ? methods.watch(`${name}.unit_cost`)
                  : 0
              ) *
                (parseFloat(
                  methods.watch(`${name}.volume`)
                    ? methods.watch(`${name}.volume`)
                    : 0
                ) *
                  parseFloat(
                    methods.watch(`${name}.qty`)
                      ? methods.watch(`${name}.qty`)
                      : 0
                  )) +
                parseFloat(
                  methods
                    .watch(`${name}.taxes`)
                    ?.reduce(
                      (acc, o) => (acc ? acc : 0) + (o?.value ? o?.value : 0),
                      0
                    )
                )
            )}
          </span>
        </span>
      </div>
    </>
  );
};

const AccountDetail = ({ name, disabled }) => {
  const location = useLocation();
  const { control, watch } = useFormContext();
  const {
    fields: activityDetails,
    append: addActivityDetail,
    remove: removeActivityDetail,
  } = useFieldArray({
    control,
    name: `${name}.activity_details`,
  });

  const isDetail =
    location.pathname.includes("detail") ||
    location.pathname.includes("approval");

  return (
    <div>
      <div className="space-y-4">
        {map(activityDetails, (activityDetail, key) => (
          <Accordion
            key={activityDetail.id}
            label="Daftar Detail Kegiatan"
            renderRight={
              !isDetail && key > 0
                ? () => (
                    <Button
                      iconOnly
                      startIcon={<BiTrash size={20} />}
                      className="px-2 mr-2 bg-red-600 border-red-600"
                      onClick={() => removeActivityDetail(key)}
                    />
                  )
                : null
            }
          >
            <div className="space-y-4">
              {/* <SelectCoaByLevelForm
              name={`${name}.activity_details.${key}.activity_detail`}
              className="w-full"
              label="Detail Kegiatan"
              placeholder="Pilih detail kegiatan"
              level={11}
              parent_id={watch(`${name}.shipping_account`)?.id}
              menuPortalTarget={document.body}
              isDisabled={disabled}
            /> */}
              <ContainerActivityDetail
                name={`${name}.activity_details.${key}`}
                shippingAccountName={`${name}.shipping_account`}
                parentLevelName={name}
                disabled={disabled}
              />
              <div className="mt-4 grid grid-cols-3 gap-4">
                <NumberInputForm
                  name={`${name}.activity_details.${key}.unit_cost`}
                  label={"Satuan Biaya (Rp)"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  disabled={disabled}
                />
                <NumberInputForm
                  name={`${name}.activity_details.${key}.qty`}
                  label={"Qty"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  disabled={disabled}
                />
                <NumberInputForm
                  name={`${name}.activity_details.${key}.volume`}
                  label={"Volume"}
                  className={`py-2 px-4 border w-full rounded-md`}
                  disabled={disabled}
                />
              </div>
              <div className="h-4" />
              <TaxForm
                name={`${name}.activity_details.${key}`}
                disabled={disabled}
              />
            </div>
          </Accordion>
        ))}
      </div>
      {!disabled && <div className="h-[1px] w-full bg-gray-400 my-4" />}
      {!disabled && (
        <div className="flex items-center justify-center">
          <Button
            onClick={() =>
              addActivityDetail({
                activity_detail: null,
                unit_cost: "",
                qty: "",
                volume: "",
                taxes: [
                  {
                    tax: null,
                    billing_code: "",
                    value: "",
                  },
                ],
              })
            }
            startIcon={<FiPlus />}
          >
            Tambah Detail Kegiatan
          </Button>
        </div>
      )}
    </div>
  );
};

const ContainerActivityDetail = ({ name, shippingAccountName, disabled, parentLevelName }) => {
  const { watch, resetField } = useFormContext();
  return (
    <SelectCoaByLevelForm
      name={`${name}.activity_detail`}
      className="w-full"
      label="Detail Kegiatan"
      placeholder="Pilih detail kegiatan"
      level={11}
      parent_id={watch(`${parentLevelName}.shipping_account`)?.id}
      menuPortalTarget={document.body}
      isDisabled={disabled}
      // onSelect={() =>
      //   resetField(`${name}.activity_detail`, { defaultValue: null })
      // }
    />
  );
};
