import React from "react";
import { Label } from "components";

const My403Component = () => {
  const goToLogout = () => {
    window.location.href = "/logout";
  }
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Label className="text-4xl font-bold">You're not allowed to access this page, </Label>
      <Label className="text-4xl font-bold">
        or your session has expired, please{" "}
        <span
          className="text-blue-500 cursor-pointer"
          onClick={goToLogout}
        >
          click here
        </span>{" "}
        to login again

      </Label>
    </div>
  );
};

export default My403Component;
