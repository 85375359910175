export const statusRekon = [
  // "Perlu Direkon",
  // "Perlu Direview",
  "Menunggu Rekon Akunting",
  // "Dana Tidak Ada",
  // "Dana Ada",
  "Rekon Selesai",
];

export const optionStatusRekon = statusRekon.map((item) => ({
  label: item,
  value: item,
}));
